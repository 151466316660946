'use client'

import { useTranslation } from '@patrianna/core-components'

import GlobalErrorContent from 'app/components/GlobalErrorContent'

function NotFoundContent() {
  const t = useTranslation()

  return <GlobalErrorContent title={t('common.page_not_found')} />
}

export default NotFoundContent
